// @ts-nocheck
import type { NextPage } from "next";

import { Logo } from "images/logo";
import Welcome from "images/welcome.svg";

import { withAuth } from "components/hoc";

const IndexPage: NextPage = () => {
  return (
    <div className="max-w-xs m-auto">
      <div className="mx-3 py-8 px-6 pb-[30px]">
        <div className="flex justify-center mb-4">
          <Logo/>
        </div>
        <p className="text-center text-md text-[#444444] font-semibold mb-8">
          Select a conversation
        </p>
        <Welcome className="m-auto" />
      </div>
    </div>
  );
};

export default withAuth(IndexPage);
