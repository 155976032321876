// @ts-nocheck
import React, {useState, useEffect} from 'react';

const Logo = ({ width = 44, height = 44, className = '' }) => (
  <div className={className}>
    <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="9.17969" y="21.9927" width="6.03507" height="6.03507" rx="1.50877" transform="rotate(-45 9.17969 21.9927)" fill="#569AD8" fillOpacity="0.5"/>
      <rect x="26.2793" y="21.9927" width="6.03507" height="6.03507" rx="1.50877" transform="rotate(-45 26.2793 21.9927)" fill="#EA9F00" fillOpacity="0.5"/>
      <rect x="17.7305" y="30.5422" width="6.03507" height="6.03507" rx="1.50877" transform="rotate(-45 17.7305 30.5422)" fill="#53A692" fillOpacity="0.5"/>
      <rect x="17.7383" y="13.4502" width="6.03507" height="6.03507" rx="1.50877" transform="rotate(-45 17.7383 13.4502)" fill="#7E69BE" fillOpacity="0.5"/>
      <rect x="0.630859" y="22" width="6.03507" height="6.03507" rx="1.50877" transform="rotate(-45 0.630859 22)" fill="#569AD8"/>
      <rect x="34.8359" y="22.0073" width="6.03507" height="6.03507" rx="1.50877" transform="rotate(-45 34.8359 22.0073)" fill="#F97316"/>
      <rect x="9.17969" y="30.5496" width="6.03507" height="6.03507" rx="1.50877" transform="rotate(-45 9.17969 30.5496)" fill="#10B981"/>
      <rect x="26.2793" y="30.5496" width="6.03507" height="6.03507" rx="1.50877" transform="rotate(-45 26.2793 30.5496)" fill="#EA9F00"/>
      <rect x="9.17969" y="13.4502" width="6.03507" height="6.03507" rx="1.50877" transform="rotate(-45 9.17969 13.4502)" fill="#0284C7"/>
      <rect x="17.7305" y="39.0994" width="6.03507" height="6.03507" rx="1.50877" transform="rotate(-45 17.7305 39.0994)" fill="#53A692"/>
      <rect x="17.7305" y="4.90063" width="6.03507" height="6.03507" rx="1.50877" transform="rotate(-45 17.7305 4.90063)" fill="#9333EA"/>
      <rect x="26.2793" y="13.4502" width="6.03507" height="6.03507" rx="1.50877" transform="rotate(-45 26.2793 13.4502)" fill="#E11D48"/>
    </svg>
  </div>
);

const AnimatedLogo = ({ size = 32, className = '', dotSize=2.5 }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsAnimating(prev => !prev);
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  const getTransform = (x, y, mirrored) => {
    const centerX = 16; // Center of the 32x32 viewBox
    const centerY = 16;
    const dx = centerX - x;
    const dy = centerY - y;
    
    if (mirrored) {
      return `translate(${2 * dx}px, ${2 * dy}px) rotate(45deg)`;
    }
    return 'rotate(45deg)';
  };

  const rectangles = [
    { x: 12, y: 16, fill: "#569AD8", fillOpacity: 0.5 },
    { x: 20, y: 16, fill: "#EA9F00", fillOpacity: 0.5 },
    { x: 16, y: 20, fill: "#53A692", fillOpacity: 0.5 },
    { x: 16, y: 12, fill: "#7E69BE", fillOpacity: 0.5 },
    { x: 8, y: 16, fill: "#569AD8" },
    { x: 24, y: 16, fill: "#F97316" },
    { x: 12, y: 20, fill: "#10B981" },
    { x: 20, y: 20, fill: "#EA9F00" },
    { x: 12, y: 12, fill: "#0284C7" },
    { x: 16, y: 24, fill: "#53A692" },
    { x: 16, y: 8, fill: "#9333EA" },
    { x: 20, y: 12, fill: "#E11D48" },
  ].map(rect => ({
    ...rect,
    x: rect.x - dotSize / 2,
    y: rect.y - dotSize / 2,
    fillOpacity: rect.fillOpacity || 1
  }));;

  return (
    <div className={className}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        {rectangles.map((rect, index) => (
          <rect
            key={index}
            x={rect.x}
            y={rect.y}
            width={dotSize}
            height={dotSize}
            fill={rect.fill}
            fillOpacity={rect.fillOpacity}
            style={{
              transition: 'transform 2000ms ease-in-out',
              transform: getTransform(rect.x + dotSize / 2, rect.y + dotSize / 2, isAnimating),
              transformOrigin: `${rect.x + dotSize / 2}px ${rect.y + dotSize / 2}px`,
            }}
          />
        ))}
      </svg>
    </div>
  );

};


export {Logo, AnimatedLogo};